<template>
    <div :class="$style.wrapper">
        <div class="col-md-2">
            <div id="preview">
                <img v-if="image" :src="image" :class="$style.displayImageFile" />
            </div>
            <div :class="$style.imageFile">
                <div :class="$style.imageFileInput">
                    <InputFile :disabled="isStaffViewing ? false : readonly" :rules="{uploadRequired:false, docCheck:'jpg,jpeg,png', checkFileRegex: true}" :value="value.data.photoName" name="Profile Photo" label="Upload Photo" @onChange="uploadFileHandler"/>
                    <p :style="{color:'grey'}">File dimensions should be 24x24 pixels</p>
                </div>
            </div>
        </div>
        <div class="col-md-10">
            <FormRow>
                <div class="col-sm-1">
                    <FormSelect label="Salutation" :disabled="readonly" :items="nameList" item-name="name" item-value="name" v-model="value.data.salutation"/>
                </div>
                <div class="col-sm-2">
                    <InputText label="First Name" :readonly="readonly" v-model="value.data.firstName" rules="required" />
                </div>
                <div class="col-sm-2">
                    <InputText label="Middle Name" :readonly="readonly" v-model="value.data.middleName " />
                </div>
                <div class="col-sm-2">
                    <InputText  label="Last Name" :readonly="readonly" v-model="value.data.lastName"/>
                </div>
                <div class="col-sm-1">
                    <InputIntegerNumber label="Emp Code" :readonly="readonly" v-model="value.data.empCode" :maxlength="5" rules="required" />
                </div>
                <div class="col-sm-1">
                    <FormSelect label="Grade" :disabled="readonly" :items="gradeList" item-name="Grade" item-value="Grade" v-model="value.data.gradeId" rules="required"/>
                </div>
                <div class="col-sm-3">
                    <InputText label="User Name" :readonly="readonly" v-model="value.data.userName" rules="required" />
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-3">
                    <InputTel label="Mobile No" :readonly="isStaffViewing ? false : readonly" v-model="value.data.mobileNo" />
                </div>
                <div class="col-sm-3">
                    <InputTel label="Phone 1" :readonly="isStaffViewing ? false : readonly" v-model="value.data.phoneNo1" />
                </div>
                <div class="col-sm-3">
                    <InputTel label="Phone 2" :readonly="isStaffViewing ? false : readonly" v-model="value.data.phoneNo2"/>
                </div>
                <div class="col-sm-3">
                    <InputTel label="Emergency Contact No" :readonly="isStaffViewing ? false : readonly" v-model="value.data.emergancyContactNo" rules="required" />
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-2">
                    <FormSelect label="Preferred Phone" :disabled="isStaffViewing ? false : readonly" :items="phoneList" item-name="name" item-value="name" v-model="value.data.preferredPhone"/>
                </div>
                <div class="col-sm-3">
                    <InputText  label="Office E-mail" :readonly="readonly" rules="required" v-model="value.data.emailId" />
                </div>
                <div class="col-sm-3">
                    <InputText label="Personal E-mail" :readonly="isStaffViewing ? false : readonly" v-model="value.data.alternateEmail"/>
                </div>
                <div class="col-sm-2">
                    <inputDate label="Date of Birth" :readonly="readonly" v-model="value.data.DOB" rules="required" />
                </div>
                <div class="col-sm-2">
                    <FormSelect :items="bloodGroupList" :disabled="readonly" item-name="BloodGroup" item-value="BloodGroup" label="Blood Group" v-model="value.data.bloodGroup"/>
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-2">
                    <FormCheckbox :disabled="isStaffViewing ? false : readonly" v-model="value.data.isMarried">Married</FormCheckbox>
                </div>
                <div class="col-sm-2">
                    <inputDate label="Anniversary Date" :readonly="isStaffViewing ? false : readonly" v-model="value.data.anniversaryDate"/>
                </div>
                <div class="col-sm-3">
                    <InputText label="Skype Id" :readonly="isStaffViewing ? false : readonly" v-model="value.data.skypeId" />
                </div>
                <div class="col-sm-1">
                    <InputIntegerNumber label="Extension" :readonly="isStaffViewing ? false : readonly" :maxlength="3" v-model="value.data.extension"/>
                </div>
                <div class="col-sm-2">
                    <InputIntegerNumber label="DID Number" :readonly="isStaffViewing ? false : readonly" :maxlength="7" v-model="value.data.DIDNo"/>
                </div>
                <div class="col-sm-2">
                    <InputIntegerNumber label="Aadhaar Number" :readonly="readonly" :maxlength="12" rules="required" v-model="value.data.aadhaarNo"/>
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-2">
                    <InputText label="PAN Number" :readonly="readonly" :maxlength="10" v-model="value.data.PANNo"/> <!--sagar changes remove pan varification 22-09-2023  -->
                </div>
                <div class="col-sm-3">
                    <InputText label="Voter Id Card Number" :readonly="readonly" v-model="value.data.voterIdCardNo"/>
                </div>
                <div class="col-sm-2">
                    <InputText label="Passport Number" :readonly="isStaffViewing ? false : readonly" :maxlength="8" v-model="value.data.passportNo"/>
                </div>
                <div class="col-sm-2">
                    <inputDate label="Passport Expiry Date" :readonly="isStaffViewing ? false : readonly" v-model="value.data.passportExpiry"/>
                </div>
                <div class="col-sm-3">
                    <InputText label="Visa Details" :readonly="isStaffViewing ? false : readonly" v-model="value.data.visaDetails"/>
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-3">
                    <FormCheckbox :disabled="readonly" v-show="isStaffViewing === false" v-model="value.data.isServiceAgreement">Service Agreement</FormCheckbox>
                </div>
                <div class="col-sm-3">
                    <inputDate label="Service Agreement End Date" :readonly="readonly" v-show="isStaffViewing === false" v-model="value.data.serviceAgreementDate"/>
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-3">
                    <h3> Permanent Address</h3>
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-4">
                    <InputText label="Permanent Address 1" :readonly="readonly" v-model="value.data.permanentAddress1" rules="required" />
                </div>
                <div class="col-sm-4">
                    <InputText label="Permanent Address 2" :readonly="readonly" v-model="value.data.permanentAddress2"/>
                </div>
                <div class="col-sm-4">
                    <InputText label="Permanent Address 3" :readonly="readonly" v-model="value.data.permanentAddress3"/>
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-3">
                    <FormSelect label="Permanent Country" :disabled="readonly" :items="countryList" item-name="Name" item-value="id" v-model="value.data.permanentCountryId" :onChange="getPermanentStateList" rules="required" />
                </div>
                <div class="col-sm-3">
                    <FormSelect label="Permanent State" :disabled="readonly" :items="permanentStateList" item-name="Name" item-value="id" v-model="value.data.permanentStateId" :onChange="getPermanentCityList" rules="required" />
                </div>
                <div class="col-sm-3">
                    <FormSelect label="Permanent City" :disabled="readonly" :items="permanentCityList" item-name="Name" item-value="id" v-model="value.data.permanentCityId" rules="required" />
                </div>
                <div class="col-sm-3">
                    <InputText label="Permanent Zip Code" :readonly="readonly" v-model="value.data.permanentZipCode" rules="required" />
                </div>
            </FormRow>
            <br/>
            <FormRow>
                <div class="col-sm-3">
                    <h3> Communication Address</h3>
                </div>
                <div class="col-sm-6">
                    <FormCheckbox :onChange="setCommunicationAdddetails" :disabled="isStaffViewing ? false : readonly" v-model="value.data.isSameAsPermanentAdd">Same as Permanent Address</FormCheckbox>
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-4">
                    <InputText label="Communication Address 1" :readonly="isStaffViewing ? false : readonly" v-model="value.data.communicationAddress1" rules="required" />
                </div>
                <div class="col-sm-4">
                    <InputText  label="Communication Address 2" :readonly="isStaffViewing ? false : readonly" v-model="value.data.communicationAddress2" />
                </div>
                <div class="col-sm-4">
                    <InputText label="Communication Address 3" :readonly="isStaffViewing ? false : readonly" v-model="value.data.communicationAddress3"/>
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-3">
                    <FormSelect label="Communication Country" :disabled="isStaffViewing ? false : readonly" :items="countryList" item-name="Name" item-value="id" v-model="value.data.communicationCountryId" :onChange="getCommunicationStateList" rules="required" />
                </div>
                <div class="col-sm-3">
                    <FormSelect label="Communication State" :disabled="isStaffViewing ? false : readonly" :items="communicationStateList" item-name="Name" item-value="id" v-model="value.data.communicationStateId" :onChange="getCommunicationCityList" rules="required" />
                </div>
                <div class="col-sm-3">
                    <FormSelect label="Communication City" :disabled="isStaffViewing ? false : readonly" :items="communicationCityList" item-name="Name" item-value="id" v-model="value.data.communicationCityId" rules="required" />
                </div>
                <div class="col-sm-3">
                    <InputText label="Communication Zip Code" :readonly="isStaffViewing ? false : readonly" v-model="value.data.communicationZipCode" rules="required" />
                </div>
            </FormRow>
        </div>
    </div>
</template>
 
<script>
    import InputDate from "Components/form/InputDate";
    import InputIntegerNumber from "Components/form/InputIntegerNumber";
    import InputTel from 'Components/form/InputTel';
    import InputFile from "Components/form/InputFile";
    import FormCheckbox from 'Components/form/FormCheckbox';
    import {getAllGrade, getBloodGroup, getCountry, getState, getCity} from "../../api";
     import config from 'Config/base';
    export default {
        name: "userPersonalInfo",
        props: ['value', 'readonly', 'isManagerViewing', 'isStaffViewing'],
        components: {
            InputDate,
            InputTel,
            InputIntegerNumber,
            FormCheckbox,
            InputFile
        },
        data() {
            return {
                nameList: [
                    {
                        name: "Mr."
                        
                    },
                    {
                        name: "Mrs."
                    },
                    {
                        name: "Ms."
                    },
                    {
                        name: "Dr."
                    },
                ],  
                phoneList: [
                    {
                        name: "Phone1"
                    },
                    {
                        name: "Phone2"
                    },
                    {
                        name: "Mobile"
                    },
                ],  
                gradeList: [],
                bloodGroupList: [],
                countryList: [],
                permanentStateList: [],
                permanentCityList: [],
                communicationStateList: [],
                communicationCityList: [],
                isSameAsPermanentAdd: false,
                isServiceAgreement: false,
                isMarried: false,
                image: null
            }
        },
        computed: {
            userData() {
                return this.$store.state.user.user;
            }
        },
        watch: {
            'value.data.isSameAsPermanentAdd': function(newVal) {
                if( newVal === true ){
                    this.value.data.isSameAsPermanentAdd = this.value.data.isSameAsPermanentAdd;
                } else if( newVal === false ){
                    this.value.data.isSameAsPermanentAdd = false;
                }
            },
            'value.data.isServiceAgreement': function(newVal) {
                if( newVal === true ){
                    this.value.data.isServiceAgreement = this.value.data.isServiceAgreement;
                } else if( newVal === false ){
                    this.value.data.isServiceAgreement = false;
                }
            },
            'value.data.isMarried': function(newVal) {
                if( newVal === true ){
                    this.value.data.isMarried = this.value.data.isMarried;
                } else if( newVal === false ){
                    this.value.data.isMarried = false;
                }
            },
            'value.data.photoName': function(newVal) {
                this.value.data.photoName = this.value.data.photoName;
            },
        },
        created() {
            this.getAllList();
        },
        methods: {
            getPermanentStateList(ele) {
                this.value.data.permanentCountryId = ele;
                if(this.value.data.permanentCountryId){
                    const data={
                        countryId: this.value.data.permanentCountryId,
                    }
                    getState(data).then((res) => {
                        this.permanentStateList = res.data;
                    });
                }
            },
            getPermanentCityList(ele) {
                this.value.data.permanentStateId = ele;
                if(this.value.data.permanentStateId){
                    const data={
                        stateId: this.value.data.permanentStateId,
                    }
                    getCity(data).then((res) => {
                        this.permanentCityList = res.data;
                    });
                }
            },
            getCommunicationStateList(ele) {
                this.value.data.communicationCountryId = ele;
                if(this.value.data.communicationCountryId){
                    const data={
                        countryId: this.value.data.communicationCountryId,
                    }
                    getState(data).then((res) => {
                        this.communicationStateList = res.data;
                    });
                }
            },
            getCommunicationCityList(ele) {
                this.value.data.communicationStateId = ele;
                if(this.value.data.communicationStateId){
                    const data={
                        stateId: this.value.data.communicationStateId,
                    }
                    getCity(data).then((res) => {
                        this.communicationCityList = res.data;
                    });
                }
            },
            async getAllList(){
                await getAllGrade().then((res)=>{
                    this.gradeList=res.data
                });
                getBloodGroup().then((res) => {
                    this.bloodGroupList = res.data;
                });
                getCountry().then((res) => {
                    this.countryList = res.data;
                });
                this.preFillAddressDropdowns();
                this.displayProfileImage();
            },
            preFillAddressDropdowns(){
                setTimeout(() => {
                    this.getPermanentStateList(this.value.data.permanentCountryId);
                },
                50
                )
                setTimeout(() => {
                    this.getPermanentCityList(this.value.data.permanentStateId);
                },
                50
                )
                setTimeout(() => {
                    this.getCommunicationStateList(this.value.data.communicationCountryId);
                },
                50
                )
                setTimeout(() => {
                    this.getCommunicationCityList(this.value.data.communicationStateId);
                },
                50
                )
            },
            uploadFileHandler(file) {
                this.value.data.photoName = file;
                if(file != null){
                    this.createImage(file);
                } else{
                    this.image = null;
                }
            },
            createImage(file) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.image = e.target.result;
                };
                reader.readAsDataURL(file);
            },
            setCommunicationAdddetails(){
                setTimeout(() => {
                    if(this.value.data.isSameAsPermanentAdd){
                        this.value.data.communicationAddress1 = this.value.data.permanentAddress1;
                        this.value.data.communicationAddress2 = this.value.data.permanentAddress2;
                        this.value.data.communicationAddress3 = this.value.data.permanentAddress3;
                        this.value.data.communicationCountryId = this.value.data.permanentCountryId;
                        this.value.data.communicationStateId = this.value.data.permanentStateId;
                        this.value.data.communicationCityId = this.value.data.permanentCityId;
                        this.value.data.communicationZipCode = this.value.data.permanentZipCode;
                        this.preFillAddressDropdowns();
                    } else{
                        this.value.data.communicationAddress1 = '';
                        this.value.data.communicationAddress2 = '';
                        this.value.data.communicationAddress3 = '';
                        this.value.data.communicationCountryId = null;
                        this.value.data.communicationStateId = null;
                        this.value.data.communicationCityId = null;
                        this.value.data.communicationZipCode = '';
                    }
                },
                50
                )
            },
            displayProfileImage(){
                this.image = '';
                if(this.value.data.displayPicture){
                    //For Settings>My Profile>My Info (Add + View mode)
                    // old code sagar
                    // if(this.$route.name === 'profileInfo'){
                    //     this.image = '../' + this.value.data.displayPicture;
                    // } else{
                    //     //For Settings>Settings>Usermanagement>Add/Edit (Add/Edit mode)
                    //     //For Hr>Team>Profile (View mode)
                    //     this.image = '.../' + this.value.data.displayPicture;
                    // }
                    this.image = config['imageUrl']+ this.value.data.displayPicture;
                }
            }
        }
    }
</script>

<style lang="scss" module>
    .wrapper {
        :global {
            .text-danger {
                display: block;
            }
        }
    }

    .imageFile {
        display: flex;
        padding: 5px 0;
        width: 150px;
    }

    .imageFileLabel {
        width: 120px;
        flex-shrink: 0;
        flex-grow: 0;
        align-items: center;
        display: flex;
    }

    .imageFileInput {
        flex-grow: 1;
    }

    .displayImageFile{
        height: 150px;
        width: 150px;
    }
</style>
<template>
    <div :class="$style.wrapper">
        <FormRow>
            <div class="col-sm-1">
                <FormSelect :items="genderList" :disabled="readonly" item-name="Name" item-value="id" label="Gender" v-model="value.data.gender"/>
            </div>
            <div class="col-sm-2">
                <InputText  label="Nationality" :disabled="readonly" v-model="value.data.nationality" />
            </div>
            <div class="col-sm-4">
                <FormSelect :items="companyList" :disabled="readonly" item-name="Name" item-value="id" label="Company" v-model="value.data.companyId" rules="required" :onChange="getList"/>
            </div>
            <div class="col-sm-2">
                <FormSelect :items="managerList" :disabled="readonly" item-name="Name" item-value="id" label="Manager" v-model="value.data.managerID" rules="required"/>
            </div>
            <div class="col-sm-2">
                <FormSelect :items="reportinglocationList" :disabled="readonly" item-name="Name" rules="required" item-value="id" label="Reporting Location" v-model="value.data.reportingLocationId"/>
            </div>
        </FormRow>
        <FormRow>
            <div class="col-sm-2">
                <FormSelect :items="divisionList" :disabled="readonly" item-name="NAME" item-value="ID" label="Division" v-model="value.data.devisionId"/>
            </div>
            <div class="col-sm-2">
                <FormSelect :items="departmentList" :disabled="readonly" item-name="Name" item-value="ID" label="Department" v-model="value.data.departmentId" rules="required"/>
            </div>
            <div class="col-sm-2">
                <FormSelect :items="designationList" :disabled="readonly" item-name="NAME" item-value="ID" label="Designation" v-model="value.data.designationId" rules="required"/>
            </div>
            <div class="col-sm-2">
                <FormSelect :items="hrgroupList" :disabled="readonly" item-name="name" item-value="id" label="HR Group" v-model="value.data.hrGroupId" rules="required"/>
            </div>
            <div class="col-sm-3">
                <FormSelect :items="shiftList" :disabled="true" item-name="Name" item-value="id" label="Shift" v-model="value.data.shiftId"  />
            </div>
        </FormRow>
        <FormRow>
            <div class="col-sm-2">
                <InputIntegerNumber  label="Account No" :readonly="readonly" v-model="value.data.accountNo"/>
            </div>
            <div class="col-sm-2">
                <InputText  label="Account Name" :readonly="readonly" v-model="value.data.accountName"/>
            </div>
            <div class="col-sm-2">
                <InputText  label="PF No" :readonly="readonly" v-model="value.data.PFNo"/>
            </div>
        </FormRow>
        <FormRow>
            <div class="col-sm-2">
                <inputDate rules="required" :readonly="readonly" label="Date of Joining " v-model="value.data.dateOfJoining"/>
            </div>
            <div class="col-sm-2">
                <inputDate rules="required" :readonly="readonly" label="Date of Confirmation" v-model="value.data.dateOfConfirmation"/>
            </div>
            <div class="col-sm-2">
                <inputDate  label="Last Appraisal Date" :readonly="readonly" v-model="value.data.lastAppraisalDate"/>
            </div>
            <div class="col-sm-2">
                <inputDate  label="Appraisal Due On" :readonly="readonly" v-model="value.data.appraisalDueOn"/>
            </div>
        </FormRow>
        <FormRow v-show="isStaffViewing === false">
            <div class="col-sm-2">
                <FormSelect :items="seprationList" :disabled="readonly" item-name="Name" item-value="id" label="Nature of Separation" v-model="value.data.separationNature"/>
            </div>
            <div class="col-sm-2">
                <inputDate label="Date of Resignation" :readonly="readonly" v-model="value.data.dateOfResignation"/>
            </div>
            <div class="col-sm-2">
                <inputDate  label="Date of Relieving" :readonly="readonly" v-model="value.data.dateOfRelieving"/>
            </div>
        </FormRow>
        <FormRow v-show="isStaffViewing === false">
            <div class="col-sm-4">
                <FormCheckbox :disabled="readonly" v-model="value.data.isManager">Is Manager</FormCheckbox>
            </div>
            <div class="col-sm-4">
                <FormCheckbox :disabled="readonly" v-model="value.data.isActive">Active</FormCheckbox>
            </div>
            <div class="col-sm-4">
                <FormCheckbox :disabled="readonly" v-model="value.data.isOnProbation">On Probation</FormCheckbox>
            </div>
        </FormRow>
        <FormRow v-show="isStaffViewing === false">
            <div class="col-sm-4">
                <FormCheckbox :disabled="readonly" v-model="value.data.isOnSite">Is OnSite</FormCheckbox>
            </div>
            <div class="col-sm-4">
                <FormCheckbox :disabled="readonly" v-model="value.data.isParttime">Is PartTime</FormCheckbox>
            </div>
            <div class="col-sm-4">
                <FormCheckbox :disabled="readonly" v-model="value.data.isEPSApplicable">Is EPS Not Applicable</FormCheckbox>
            </div>
        </FormRow>
    </div>
</template>

<script>
    import InputDate from "Components/form/InputDate";
    import InputIntegerNumber from "Components/form/InputIntegerNumber";
    import FormCheckbox from 'Components/form/FormCheckbox';
    import {getDepartmentByCompanyId, getAllCompanyList, getAllDivision, getAllDesignation, getAllHRGroup, getAllShift, getReportingLocation, getManagerList} from "../../api";
    export default {
        name: "userDetails",
        props: ['value', 'readonly', 'isManagerViewing', 'isStaffViewing'],
        components: {
            InputIntegerNumber,
            InputDate,
            FormCheckbox
        },
        data() {
            return {
                genderList:[
                    {
                        id: 'M',
                        Name: "Male"
                    },
                    {
                        id: 'F',
                        Name: "Female"
                    }
                ],
                seprationList:[
                    {
                        id: 'A',
                        Name: "Absconding"
                    },
                    {
                        id: 'R',
                        Name: "Resignnation"
                    },
                    {
                        id: 'T',
                        Name: "Termination",
                    }
                ],
                companyList:[],
                divisionList:[],
                designationList:[],
                hrgroupList:[],
                shiftList:[],
                reportinglocationList:[],
                managerList:[],
                departmentList: []
            }
        },
        computed: {
            userData() {
                return this.$store.state.user.user;
            }
        },
        watch: {
            'value.data.data.devisionId': function(newVal){
                if( newVal === true ){
                    this.value.data.devisionId = this.value.data.devisionId;
                } else if( newVal === false ){
                    this.value.data.devisionId = false;
                }
            },
            'value.data.managerID': function(newVal){
                if( newVal === true ){
                    this.value.data.managerID = this.value.data.managerID;
                } else if( newVal === false ){
                    this.value.data.managerID = false;
                }
            },
            'value.data.isManager': function(newVal){
                if( newVal === true ){
                    this.value.data.isManager = this.value.data.isManager;
                } else if( newVal === false ){
                    this.value.data.isManager = false;
                }
            },
            'value.data.isParttime': function(newVal){
                if( newVal === true ){
                    this.value.data.isParttime = this.value.data.isParttime;
                } else if( newVal === false ){
                    this.value.data.isParttime = false;
                }
            },
            'value.data.isOnSite': function(newVal){
                if( newVal === true ){
                    this.value.data.isOnSite = this.value.data.isOnSite;
                } else if( newVal === false ){
                    this.value.data.isOnSite = false;
                }
            },
            'value.data.isOnProbation': function(newVal){
                if( newVal === true ){
                    this.value.data.isOnProbation = this.value.data.isOnProbation;
                } else if( newVal === false ){
                    this.value.data.isOnProbation = false;
                }
            },
            'value.data.isActive': function(newVal){
                if( newVal === true ){
                    this.value.data.isActive = this.value.data.isActive;
                } else if( newVal === false ){
                    this.value.data.isActive = false;
                }
            },
            'value.data.isEPSApplicable': function(newVal){
                if( newVal === true ){
                    this.value.data.isEPSApplicable = this.value.data.isEPSApplicable;
                } else if( newVal === false ){
                    this.value.data.isEPSApplicable = false;
                }
            }
        },
        created() {
            this.getAllList();
            this.getList(this.value.data.companyId);
        },
        methods: {
            async  getAllList(){
                await getAllCompanyList().then((res) => {
                    this.companyList = res.data;
                });
                getAllDivision().then((res) => {
                    this.divisionList = res.data;
                });
                getAllDesignation().then((res) => {
                    this.designationList = res.data;
                });
                getAllHRGroup().then((res) => {
                    this.hrgroupList = res.data;
                });
                getAllShift().then((res) => {
                    this.shiftList = res.data;
                });
                getManagerList().then((res) => {
                    this.managerList = res.data;
                });
            },
            getList(ele) {
                if(this.value.data.companyId == null){
                    this.value.data.companyId = ele;
                }
                if(this.value.data.companyId){
                    const data={
                        companyId: this.value.data.companyId,
                    }
                    getDepartmentByCompanyId(data).then((res)=>{
                        this.departmentList=res.data
                    });
                    getReportingLocation(data).then((res) => {
                        this.reportinglocationList = res.data;
                    });
                }
            },
        }
    }
</script>

<style lang="scss" module>
    .wrapper {
        :global {
            .text-danger {
                display: block;
            }
        }
    }
</style>

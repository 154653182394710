<template>
    <div :class="$style.actions">
        <div >
            <template v-if="row.DocumentName">
                <FormButton size="small" type="text" onclick="window.open(this.href,'_blank');return false;" :href="'../../'+row.DocumentName" v-if="row.DocumentName" tag="a" left-icon="md-eye">View</FormButton>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ActionsCell",
        props: {
            row: {
                type: Object,
                required: true,
            }
        },
        computed:{
            userData() {
                return this.$store.state.user.user
            }
        },
        methods: {
        }
    }
</script>

<style lang="scss" module>

    .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        a,
        button {
            margin: 0 5px
        }
        div {
            margin: 0 5px;
            color: #55a7a0;
            display: inline-block;
            &:hover {
                color: #55a7a0;
            }
            &.error {
                color: #eac222;
                font-size: 19px;
                &:hover {
                    font-size: 19px;
                }
            }
        }
    }
</style>


export default {
//userPersonalInfo Page fields
    userId: 0,
    salutation: null,
    firstName:'',
    middleName:'',
    lastName:'',
    empCode:'',
    gradeId: null,
    userName:'',
    phoneNo1:'',
    phoneNo2:'',
    preferredPhone:'',
    mobileNo:'',
    emergancyContactNo:'',
    emailId: '',
    alternateEmail: '',
    DOB:'',
    bloodGroup: null,
    isMarried: false,
    anniversaryDate: '',
    skypeId: '',
    extension: '',
    DIDNo: '',
    aadhaarNo: '',
    PANNo: '',
    photoName: null,
    displayPicture: null,
    voterIdCardNo: '',
    passportNo: '',
    passportExpiry: '',
    visaDetails: '',
    isServiceAgreement: false,
    serviceAgreementDate: '',
    permanentAddress1: '',
    permanentAddress2: '',
    permanentAddress3: '',
    permanentCountryId: null,
    permanentStateId: null,
    permanentCityId: null,
    permanentZipCode: '',
    isSameAsPermanentAdd: false,
    communicationAddress1: '',
    communicationAddress2: '',
    communicationAddress3: '',
    communicationCountryId: null,
    communicationStateId: null,
    communicationCityId: null,
    communicationZipCode: '',
//userDetails Page fields
    gender: null,
    nationality: '',
    companyId: null,
    devisionId: null,
    departmentId: null,
    designationId: null,
    hrGroupId: null,
    managerID: null,
    accountNo: '',
    accountName: '',
    PFNo: '',
    reportingLocationId: null,
    phoneNo: '',
    shiftId: null,
    dateOfJoining: '',
    dateOfConfirmation: '',
    lastAppraisalDate: '',
    appraisalDueOn: '',
    separationNature: null,
    dateOfResignation: '',
    dateOfRelieving: '',
    isManager: false,
    isParttime: false,
    isOnSite: false,
    isOnProbation: false,
    isActive: false,
    isEPSApplicable: false,
//userRoles Page fields
    roles: ''
}

<template>
    <div :class="$style.wrapper">
        <DataTable :actions="true"
            :data="rewardsList"
            :columns="tableConfig"
            :is-loading="isLoading"
            @on-page-change="pageChange"
            :pagination="pagination">
        </DataTable>
    </div>
</template>

<script>
    import DataTable from 'Components/DataTable'
    import loadingMixin from 'Mixins/loadingMixin'
    import tableConfig from './awardsTable'
    import {getStaffAwardList} from '../../api'
    export default {
        name: "userAwards",
        mixins: [loadingMixin],
        props: ['value', 'readonly', 'isManagerViewing', 'isStaffViewing'],
        components: {
            DataTable
        },
        data() {
            return {
                sorting: {
                    key: 'Name',
                    order: 'asc'
                },
                pagination: {
                    perPage: 10,
                    page: 1,
                    total: 0
                },
                tableConfig,
                rewardsList: []
            }
        },
        computed: {
            userData() {
                return this.$store.state.user.user;
            }
        },
        watch: {
        },
        created() {
            this.getData()
        },
        methods: {
            getData() {
                setTimeout(() => {
                    this.showLoader();
                    const { key, order } = this.sorting;
                    const { perPage, page } = this.pagination;
                    const filters = {};
                    filters.staffId = this.value.data.userId;
                    const data = {
                        filterjson: {
                            filter: [filters],
                            sort: [{ key, order }],
                            paging: [
                                {
                                    startIndex: page,
                                    pageSize: perPage
                                }
                            ]
                        }
                    };
                    getStaffAwardList(data)
                        .then(this.handleResponse)
                        .catch(this.handleError)
                },
                50
                );
            },
            pageChange(page) {
                this.pagination.page = page;
                this.getData()
            },
            handleResponse(response) {
                this.hideLoader();
                this.rewardsList = response.data;
                this.pagination.total = response.NumberOfRecords;
            },
        }
    }
</script>

<style lang="scss" module>
    .wrapper {
        :global {
            .text-danger {
                display: block;
            }
        }
    }
</style>

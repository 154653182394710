<template>
    <div :class="$style.wrapper">
        <FormRow>
            <div class="col-sm-3" >
                <FormSelect label="Document Category" :items="documentCategoryList" item-name="DocumentCategory" item-value="id"  v-model="filtering.selectedDocumentCategory" />
            </div>
            <div class="col-sm-3">
                <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
                <Icon type="ios-search" />
            </FormButton>
            </div>
        </FormRow>
        <DataTable :actions="true"
            :data="documentsList"
            :columns="tableConfig"
            :is-loading="isLoading"
            @on-page-change="pageChange">
        </DataTable>
    </div>
</template>

<script>
    import DataTable from 'Components/DataTable'
    import loadingMixin from 'Mixins/loadingMixin'
    import tableConfig from './documentsTable'
    import {getStaffDocumentList, documentCategory} from '../../api'
    export default {
        name: "userDocuments",
        mixins: [loadingMixin],
        props: ['value', 'readonly', 'isManagerViewing', 'isStaffViewing'],
        components: {
            DataTable
        },
        data() {
            return {
                filtering: {
                    selectedDocumentCategory: null
                },
                sorting: {
                    key: 'Task',
                    order: 'asc'
                },
                pagination: {
                    perPage: 10,
                    page: 1,
                    total: 0
                },
                tableConfig,
                documentsList: [],
                documentCategoryList: []
            }
        },
        computed: {
            userData() {
                return this.$store.state.user.user;
            }
        },
        watch: {
        },
        created() {
            this.getAllList();
            this.getDataByFilter();
        },
        methods: {
            getDataByFilter() {
                if(this.isManagerViewing === false){
                    setTimeout(() => {
                        this.showLoader();
                        const { key, order } = this.sorting;
                        const { perPage, page } = this.pagination;
                        const filters = {};
                        filters.staffId = this.value.data.userId;
                        if (this.filtering.selectedDocumentCategory) {
                            filters.documentCategoryId = this.filtering.selectedDocumentCategory;
                        }
                        const data = {
                            filterjson: {
                                filter: [filters],
                                sort: [{ key, order }],
                                paging: [
                                    {
                                        startIndex: page,
                                        pageSize: perPage
                                    }
                                ]
                            }
                        };
                        getStaffDocumentList(data)
                            .then(this.handleResponse)
                            .catch(this.handleError)
                    },
                    50
                    );
                }
            },
            pageChange(page) {
                this.pagination.page = page;
                this.getDataByFilter()
            },
            search(){
                this.pagination.page = 1;
                this.getDataByFilter()
            },
            handleResponse(response) {
                this.hideLoader();
                this.documentsList = response.data;
                this.pagination.total = response.NumberOfRecords;
            },
            async getAllList() {
                await documentCategory().then((res)=>{
                    this.documentCategoryList=res.data;
                })
            }
        }
    }
</script>

<style lang="scss" module>
    .wrapper {
        :global {
            .text-danger {
                display: block;
            }
        }
    }
</style>

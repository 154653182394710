import AwardsActionsCell from '../components/AwardsActionsCell';

export default [
    {
        title: 'Staff Name',
        key: 'Name',
        minWidth: 80,
    },
    
    {
        title: 'Award',
        key: 'AwardName',
        minWidth: 80,
    },

    {
        title: 'Month',
        key: 'Month',
        minWidth: 80,
    },

    {
        title: 'Year',
        key: 'Year',
        minWidth: 80,
    },

    {
        title: 'Nominated By',
        key: 'NominatedBy',
        minWidth: 80,
    },
    
    {
        title: 'Attachment',
        width: 80,
        align: 'center',
        render: (h, params) => {
            return h('div', [
                h(AwardsActionsCell, {
                    props: {
                        row: params.row
                    }
                }),
            ]);
        }
    },
]
